export default {
  "article": {
    "allergens": "Allerginen? Keskustele henkilökuntamme kanssa, niin autamme sinua."
  },
  "button": {
    "add": "Lisää",
    "back": "Takaisin",
    "backToArticle": "Palaa tuotteeseen {article}",
    "backToCategories": "Palaa kategorioihin",
    "backToCategory": "Palaa kategoriaan {category}",
    "backToHome": "Keskeytä tilaus",
    "backToMenuRoot": "Takaisin",
    "backToShopPage": "Keskeytä tilaus",
    "cancel": "Keskeytä",
    "cancelOrder": "Keskeytä tilaus",
    "change": "Muokkaa",
    "changeLanguage": "Vaihda kieli",
    "changeRestaurant": "Vaihda ravintola",
    "choiceDone": "Valmis",
    "closed": "Suljettu",
    "dineInPlace": "Syön ravintolassa",
    "done": "Valmis",
    "favorite": "Suosikki",
    "no": "Ei",
    "noThanks": "Ei kiitos",
    "offline": "Offline",
    "open": "Avoinna",
    "order": "Tilaa",
    "orderNotReady": "Tilaus ei ole valmis",
    "paused": "Suljettu tilauksilta",
    "pay": "Maksa",
    "receipt": "Kuitti",
    "showMore": "Näytä enemmän",
    "showMoreRestaurants": "Näytä enemmän ravintoloita",
    "takeAway": "Otan mukaan",
    "yes": "Kyllä"
  },
  "campaign": {
    "bannerText": "Kampanja"
  },
  "campaigns": {
    "description": "Alla näet kaikki tällä hetkellä voimassa olevat tarjoukset. Jos olet kirjautunut sisään, tarjouksia saattaa olla enemmän.",
    "descriptionAuthenticated": "Alla näet kaikki tällä hetkellä voimassa olevat tarjoukset.",
    "expandButton": {
      "text": "Näytä enemmän"
    },
    "header": "Tarjoukset",
    "localOffer": {
      "text": "Paikallinen tarjous"
    },
    "membersOnly": {
      "text": "Jäsentarjous"
    },
    "offer": {
      "text": "Tarjous"
    },
    "showMoreButton": {
      "text": "Katso kaikki tarjoukset"
    },
    "toArticle": {
      "text": "Siirry tarjoukseen"
    }
  },
  "cancel": {
    "header": "Keskeytä",
    "order": {
      "cancel": "Ei, jatka",
      "confirm": "Kyllä, keskeytän tilauksen",
      "description": "Haluatko keskeyttää tilauksesi?",
      "header": "Keskeytä tilaus"
    },
    "text": "Haluatko keskeyttää tilauksen?"
  },
  "checkout": {
    "header": "Kassa",
    "orderHeader": "Tilaukseni",
    "pickup": "Noutoaika"
  },
  "confirmEmail": {
    "header": "Sähköposti vahvistettu",
    "text": "Tilisi on vahvistettu, voit nyt kirjautua sisään."
  },
  "error": {
    "DuplicateEmail": "Yritä kirjautua sisään tai valitse \"Unohditko salasanasi?\"",
    "DuplicateUserName": "Tällä sähköpostiosoitteella on jo tili.",
    "InvalidToken": "Salasanasi palautustunnus on virheellinen tai vanhentunut. Palauta salasana uudelleen.",
    "One or more validation errors occurred": "Tapahtui yksi tai useampi vahvistusvirhe.",
    "PasswordMismatch": "Salasanat eivät täsmää.",
    "PasswordRequiresDigit": "Salasanassa on oltava vähintään yksi numero ('0'-'9').",
    "PasswordRequiresLower": "Salasanassa on oltava vähintään yksi pieni kirjain ('a'-'z').",
    "PasswordRequiresNonAlphanumeric": "Salasanassa on oltava vähintään yksi erikoismerkki.",
    "PasswordRequiresUpper": "Salasanassa on oltava vähintään yksi iso kirjain ('A'-'Z').",
    "PasswordTooShort": "Salasanan tulee olla vähintään 6 merkkiä pitkä.",
    "badRequest": "Selaimesi lähetti pyynnön, jota tämä palvelin ei ymmärtänyt.",
    "closeButtonText": "Sulje",
    "deliverySpotRequired": "Ole hyvä ja valitse toimituspiste ennen kuin jatkat.",
    "heading": "Virheviesti",
    "menu": {
      "not-found": "Ruokalistaa ei löytynyt ravintolalle, jonka tunnus on {shopId}."
    },
    "notFound": "Sivua ei löytynyt. Tämä voi johtua siitä, että tuotetta tai sivua ei ole saatavilla tälle ravintolalle.",
    "order": {
      "create-order-failed": "Jostain syystä emme voineet nyt luoda tilaustasi. Ole hyvä ja yritä myöhemmin uudelleen.",
      "create-order-failed-missing-recipe-items": "Joistakin tilauksesi tuotteista puuttuu tarvittavat ainesosat.",
      "preorder-create-order-failed": {
        "internal-server-error": "Tilausjärjestelmässä tapahtui tuntematon virhe. Ole hyvä ja yritä myöhemmin uudelleen.",
        "invalid-parameter": "Vähintään yksi virheellinen arvo on syötetty. Tarkista tiedot ja yritä uudelleen.",
        "invalid-shop-id": "Virheellinen ravintolatunnus. Tarkista tunnus ja yritä uudelleen.",
        "missing-parameter": "Pakollinen kenttä puuttuu. Tarkista tilauksesi ja yritä uudelleen.",
        "shop-error-articles-not-available": "Jotkut tuotteet eivät ole saatavilla. Ole hyvä ja päivitä tilauksesi. ",
        "shop-error-cashier-not-found": "Kassanhoitajaa ei löydy. Ole hyvä ja yritä myöhemmin uudelleen.",
        "shop-error-currency-error": "Tapahtui valuuttavirhe. Tarkista valuutta ja yritä uudelleen.",
        "shop-error-database-error": "Tapahtui tietokantavirhe. Ole hyvä ja yritä myöhemmin uudelleen.",
        "shop-error-date-time-in-the-past": "Valittu päivämäärä tai aika on mennyt. Ole hyvä ja valitse myöhempi päivämäärä/aika.",
        "shop-error-failed-to-init-coffe-card": "Kahvikortin alustaminen epäonnistui. Ole hyvä ja yritä myöhemmin uudelleen.",
        "shop-error-failed-to-init-coffe-card-campaing": "Kahvikorttikampanjan alustaminen epäonnistui. Ole hyvä ja yritä myöhemmin uudelleen.",
        "shop-error-failed-to-open-database": "Tietokannan avaaminen epäonnistui. Ole hyvä ja yritä myöhemmin uudelleen.",
        "shop-error-internal-error": "Ravintolassa tapahtui sisäinen virhe. Ole hyvä ja yritä myöhemmin uudelleen.",
        "shop-error-internal-error-in-call": "Sisäinen virhe kommunikoitaessa ravintolan kanssa. Ole hyvä ja yritä myöhemmin uudelleen.",
        "shop-error-internal-error-post-order-check-error": "Tilauksen jälkeen tapahtui sisäinen virhe. Ole hyvä ja yritä uudelleen.",
        "shop-error-internal-error-post-order-fixed": "Tapahtui sisäinen virhe, mutta se on korjattu. Ole hyvä ja jatka.",
        "shop-error-internal-error-post-order-not-fixed": "Tapahtui sisäinen virhe, jota ei voitu korjata. Ole hyvä ja yritä myöhemmin uudelleen.",
        "shop-error-invalid-date-time": "Virheellinen päivämäärä tai aika. Tarkista tiedot ja yritä uudelleen.",
        "shop-error-no-central-web-plu": "Central web PLU puuttuu. Ota yhteyttä tukeen.",
        "shop-error-order-is-deleted": "Tilaus on poistettu.",
        "shop-error-order-is-delivered": "Tilaus on jo toimitettu.",
        "shop-error-order-is-started": "Tilaus on jo aloitettu, eikä sitä voi muuttaa.",
        "shop-error-order-not-found": "Tilausta ei löytynyt. Ole hyvä ja tarkista tilauksesi tiedot.",
        "shop-error-payment-not-found": "Maksua ei löytynyt. Ole hyvä ja yritä uudelleen.",
        "shop-error-profit-center-not-found": "Kustannuspaikkaa ei löytynyt. Ota yhteyttä tukeen.",
        "shop-error-recipe-items-missing": "Osa tilauksen ainesosista puuttuu. Ole hyvä ja ota yhteyttä ravintolaan.",
        "shop-error-slot-not-available": "Valittu aikaväli ei ole käytettävissä, ole hyvä ja valitse toinen.",
        "shop-error-slots-not-in-use": "Aikavälit eivät ole tällä hetkellä käytössä. Ole hyvä ja yritä myöhemmin uudelleen.",
        "shop-error-stock-center-not-found": "Varastopaikkaa ei löytynyt. Ole hyvä ja yritä myöhemmin uudelleen.",
        "shop-error-terminal-not-found": "Ravintolan terminaalia ei löytynyt. Ole hyvä ja yritä myöhemmin uudelleen.",
        "shop-error-timeout-waiting-for-call-lock": "Tapahtui aikakatkaisu vastausta odotellessa. Ole hyvä ja yritä uudelleen.",
        "shop-method-not-supported": "Ravintola ei tue tätä tilaustapaa, kokeile toista tapaa.",
        "shop-not-found": "Ravintola, josta yrität tilata, on joko suljettu tai suljettu pysyvästi. Yritä myöhemmin uudelleen.",
        "shop-offline": "Ravintola, josta yrität tilata, on offline-tilassa. Yritä myöhemmin uudelleen.",
        "shop-paused": "Ravintola, josta yrität tilata, ei tällä hetkellä ota vastaan ​​verkkotilauksia. Yritä myöhemmin uudelleen.",
        "unknown-error": "Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen."
      },
      "shop-closed": "Ravintola ei ole enää auki. Ole hyvä ja yritä myöhemmin uudelleen.",
      "shop-closed-at-pickuptime": "Ravintola on suljettu valitsemanasi noutoaikana.",
      "shop-paused": "Ravintola ei valitettavasti ota vastaan verkkotilauksia juuri nyt. Yritä myöhemmin uudelleen.",
      "unavailable-products": "Yksi tai useampi tilauksesi tuotteista ei ole enää saatavilla."
    },
    "payment": {
      "IdentifierMissing": "Maksun yhteydessä tapahtui tuntematon virhe (code:06)",
      "NotFound": "Maksun yhteydessä tapahtui tuntematon virhe (code:07)",
      "aborted": "Maksun yhteydessä tapahtui tuntematon virhe (code:02)",
      "accesstokenMissing": "Maksun yhteydessä tapahtui tuntematon virhe (code:05)",
      "captureFailed": "Maksun yhteydessä tapahtui tuntematon virhe (code:13)",
      "createError": "Maksun yhteydessä tapahtui tuntematon virhe (code: 14)",
      "failed": "Maksun yhteydessä tapahtui tuntematon virhe (code:01)",
      "invalid": "Maksun yhteydessä tapahtui tuntematon virhe (code:04)",
      "loadScriptError": "Maksun yhteydessä tapahtui tuntematon virhe (kod: 15)",
      "merchantNotFound": "Maksun yhteydessä tapahtui tuntematon virhe (code:08)",
      "notPaid": "Maksun yhteydessä tapahtui tuntematon virhe (code:03)",
      "orderFailed": "Maksun yhteydessä tapahtui tuntematon virhe (code:12)",
      "orderNotFound": "Maksun yhteydessä tapahtui tuntematon virhe (code:09)",
      "requestParametersMissing": "Maksun yhteydessä tapahtui tuntematon virhe (code:11)",
      "returnUrl": "Maksun yhteydessä tapahtui tuntematon virhe (code:10)"
    },
    "server": "Palvelinvirhe, yritä uudelleen.",
    "unknown": "Tuntematon virhe, yritä uudelleen.",
    "user": {
      "invalid-password": "Salasana ei täytä vähimmäisvaatimuksia.",
      "password-mismatch": "Salasanat eivät täsmää.",
      "register-user-failure": "Tilin luominen epäonnistui.",
      "send-email-confirmation-failed": "Sähköpostivahvistuksen lähettäminen epäonnistui."
    }
  },
  "favorites": {
    "empty": "Sinulla ei vielä ole suosikkeja",
    "header": "Omat suosikkini"
  },
  "forgotPassword": {
    "email": {
      "label": "Sähköposti",
      "placeholder": "Sähköposti"
    },
    "header": "Unohdin salasanani",
    "send": {
      "text": "Lähetä"
    },
    "sent": {
      "header": "Sähköposti lähetetty",
      "message": "Palautusviesti on lähetetty annettuun sähköpostiosoitteeseen. Jos et ole saanut sähköpostia muutaman minuutin kuluessa, annettuun sähköpostisoitteeseen ei ole linkitetty tiliä.",
      "newAccountText": "Luo tili"
    },
    "text": "Oletko unohtanut salasanasi? Syötä sähköpostiosoitteesi saadaksesi palautusviestin."
  },
  "home": {
    "header": "Tervetuloa!",
    "subHeader": "Valitse ravintola"
  },
  "item": {
    "favoriteHeader": "Omat suosikkini",
    "notAvailable": "Tuote loppu"
  },
  "label": {
    "articleChoiceLabel1": "Muokkaa/lisää",
    "articleChoiceLabel2": "Muokkaa",
    "articleChoiceLabel3": "Valitse lisukkeet",
    "articleChoiceLabel4": "Muokkaa",
    "articleChoiceLabel5": "Valitse maku",
    "dineInPlace": "Syön ravintolassa",
    "now": "Nyt",
    "subtotal": "Välisumma",
    "takeAway": "Otan mukaan",
    "tax": "Alv ({percent}%)",
    "total": "Yhteensä"
  },
  "list": {
    "menu": "Menu"
  },
  "loader": {
    "calculatesDistances": "Lasketaan etäisyydet",
    "categories": "Etsitään kategorioita",
    "checkoutSummary": "Luodaan yhteys ravintolaan",
    "favorites": "Haetaan suosikkejasi",
    "languages": "Etsitään kielivaihtoehtoja",
    "orderHistory": "Haetaan tilaushistoriaasi",
    "text": "Ladataan..."
  },
  "login": {
    "confirm": "Kirjaudu sisään",
    "email": "Sähköposti",
    "facebookSignIn": "Kirjaudu sisään Facebookilla",
    "failed": "Kirjautuminen epäonnistui. Yritä uudelleen.",
    "forgotPassword": "Unohdin salasanani",
    "googleSignIn": "Kirjaudu sisään Googlella",
    "header": "Kirjaudu sisään",
    "password": "Salasana",
    "registerLink": "Luo tili",
    "registerText": "Eikö sinulla ole vielä tiliä?",
    "separatorText": "Tai"
  },
  "memberCard": {
    "header": "Digitaalinen klubikorttini",
    "text": "Skannaa minut kassalla tai itsepalvelupisteellä"
  },
  "navBar": {
    "login": "Kirjaudu sisään"
  },
  "offline": {
    "button": "Yritä uudelleen",
    "header": "Internet-yhteyden muodostaminen ei onnistu.",
    "text": "Tarkista internet-yhteytesi ja yritä uudelleen."
  },
  "order": {
    "campaignLabel": "Kampanja",
    "deliverySpot": {
      "empty": "Ei valittu...",
      "label": "Toimitus osoitteeseen"
    },
    "emptyInfoText": "Ostoskorisi on vielä tyhjä",
    "header": "Tilaukseni",
    "infoText": "(Napauta tuotetta tehdäksesi muutoksia)",
    "infoText2": "(Mahdolliset alennukset vähennetään seuraavassa vaiheessa)",
    "missing": "Tilaus puuttuu",
    "note": {
      "label": "Muut tiedot",
      "placeholder": "Syötä muut tiedot",
      "remove": "Poista"
    },
    "numberLabel": "Tilausnumerosi",
    "pageHeader": "Tilauksesi",
    "pickUpLabel": "Arvioitu noutoaika",
    "selectedRestaurant": "Valittu ravintola",
    "status": {
      "added": {
        "description": "Tilauksesi on vastaanotettu ja sitä käsitellään.",
        "label": "Tilauksesi on vastaanotettu"
      },
      "calculatedprice": {
        "label": "Vahvistetaan tilausta..."
      },
      "delivered": {
        "description": "Toivottavasti ruoka maistuu!",
        "label": "Toimitettu"
      },
      "finnished": {
        "description": "Tilauksesi on valmis noudettavaksi.",
        "label": "Valmiina noudettavaksi!"
      },
      "rollbacked": {
        "description": "Tilaus keskeytetty. Korttisi varaus on poistettu. Jos olet maksanut Swishillä, ota yhteyttä ravintolaan hyvitystä varten.",
        "label": "Keskeytetty"
      },
      "started": {
        "description": "Tilaustasi valmistellaan.",
        "label": "Tilaustasi valmistellaan!"
      }
    },
    "statusLabel": "Nykyinen tila",
    "thankYouText": "Kiitos tilauksestasi"
  },
  "orderHistory": {
    "empty": "Tilaushistoriasi on tyhjä.",
    "header": "Tilaushistoria"
  },
  "payment": {
    "confirmLeave": {
      "cancel": "Ei, en halua poistua.",
      "confirm": "Kyllä, olen varma.",
      "header": "Vahvista poistuminen",
      "text": "Haluatko varmasti poistua sivulta? Poistuminen nyt voi estää meitä päivittämästä sinulle oikeaa tilauksen tilaa, koska maksusi on vielä kesken."
    },
    "header": "Maksa tilaus"
  },
  "popup": {
    "buttons": {
      "close": "Sulje"
    }
  },
  "privacyPolicy": {
    "header": "Tietosuojakäytäntö"
  },
  "profile": {
    "confirm-email": {
      "header": "Vahvista sähköposti",
      "text": "Tilisi on vahvistettu, voit nyt kirjautua sisään."
    },
    "edit": {
      "changePassword": {
        "confirmNewPassword": {
          "description": "Vahvista salasana, johon haluat vaihtaa.",
          "label": "Vahvista uusi salasana",
          "placeholder": "Syötä uusi salasanasi uudelleen"
        },
        "header": "Vaihda salasana",
        "newPassword": {
          "description": "Salasana, johon haluat vaihtaa",
          "label": "Uusi salasana",
          "placeholder": "Syötä uusi salasanasi"
        },
        "password": {
          "description": "Nykyinen salasana, jolla kirjaudut sovellukseen",
          "label": "Nykyinen salasana",
          "placeholder": "Syötä nykyinen salasanasi"
        }
      },
      "deleteAccount": {
        "confirm": {
          "cancel": "Keskeytä",
          "description": "Haluatko varmasti poistaa tilisi?",
          "header": "Poistetaanko tili?",
          "ok": "Kyllä, poista"
        },
        "text": "Poista tili",
        "title": "Poista tilisi"
      },
      "emailConfirmationSent": "Viesti on lähetetty annettuun sähköpostiosoitteeseen. Tarkista viestisi ja seuraa ohjeita sähköpostiosoitteesi vahvistamiseksi.",
      "header": "Oma profiili",
      "submit": {
        "text": "Tallenna",
        "title": "Tallenna tilitiedot"
      },
      "success": {
        "header": "Tallennettu!",
        "text": "Tietosi on tallennettu."
      },
      "userInformation": {
        "email": {
          "description": "Käytät sähköpostiasi kirjautuessasi sisään",
          "label": "Sähköposti",
          "placeholder": "Syötä sähköpostiosoite"
        },
        "header": "Käyttäjätiedot",
        "lastName": {
          "description": "Sukunimesi näkyy tilitiedoissasi",
          "label": "Sukunimi",
          "placeholder": "Syötä sukunimesi"
        },
        "name": {
          "description": "Nimesi näkyy tilitiedoissasi",
          "label": "Nimi",
          "placeholder": "Syötä nimesi"
        }
      }
    },
    "menu": {
      "account": "Oma tili",
      "favorites": "Omat suosikkini",
      "logout": "Kirjaudu ulos",
      "offers": "Tarjoukset",
      "orderHistory": "Tilaushistoria",
      "userGreeting": "Hei!"
    }
  },
  "receipt": {
    "amountPrefix": "à",
    "deliveryDate": "Toimitusajankohta",
    "deliverySpot": "Toimitusosoite",
    "email": "Sähköpostiosoite:",
    "emailFailed": "Kuitin lähettäminen sähköpostitse epäonnistui. Yritä uudelleen.",
    "emailPlaceHolder": "Syötä sähköpostiosoitteesi",
    "emailSent": "Sähköposti lähetetty",
    "heading": "Kuitti",
    "loading": "Lähetetään kuittia ...",
    "net": "Netto",
    "note": "Muut tiedot",
    "orderNumber": "TilausNro",
    "paymentId": "Maksutunnus",
    "sendEmail": "Lähetä kuitti",
    "total": "Yhteensä",
    "vat": "Alv"
  },
  "register": {
    "acceptTerms": {
      "label": "Hyväksyn",
      "linkText": "ehdot"
    },
    "confirmPassword": {
      "label": "Toista salasana",
      "placeholder": "Toista salasana"
    },
    "email": {
      "label": "Sähköposti",
      "placeholder": "Syötä sähköpostiosoite"
    },
    "firstname": {
      "label": "Etunimi",
      "placeholder": "Syötä etunimesi"
    },
    "header": "Luo tili",
    "phone": {
      "label": 'Puhelinnumero', 
      "placeholder": 'Syötä puhelinnumero'
    },
    "lastname": {
      "label": "Sukunimi",
      "placeholder": "Syötä sukunimesi"
    },
    "password": { 
      "label": "Salasana",
      "placeholder": "Syötä salasana"
    },
    "passwordMismatch": {
      "text": "Salasanat eivät täsmää"
    },
    "registerButton": "Luo tili",
    "success": {
      "header": "Tili luotu",
      "loginLink": "Kirjaudu sisään",
      "text": "Viesti on lähetetty annettuun sähköpostiosoitteeseen. Tarkista viestisi ja seuraa ohjeita sähköpostiosoitteesi vahvistamiseksi. Tämän jälkeen voit kirjautua sisään."
    }
  },
  "resetPassword": {
    "confirmPassword": {
      "label": "Vahvista uusi salasana",
      "placeholder": "Syötä uusi salasana uudelleen"
    },
    "header": "Palauta salasana",
    "password": {
      "label": "Uusi salasana",
      "placeholder": "Syötä uusi salasana"
    },
    "reset": {
      "header": "Salasana palautettu",
      "message": "Salasanasi on palautettu. Voit kirjautua sisään uudella salasanallasi."
    },
    "send": {
      "text": "Palauta salasana"
    }
  },
  "search": {
    "label": "Etsi ravintola",
    "noMatch": "Vastaavia ravintoloita ei löytynyt",
    "placeholder": "Löydä ravintola"
  },
  "selectedShop": {
    "header": "Valitse missä haluat syödä"
  },
  "shopSelector": {
    "singleShop": {
      "closed": "Suljettu tältä päivältä",
      "offline": "Offline-tilassa",
      "openingHoursHeader": "Aukioloajat",
      "paused": "Suljettu tilauksilta",
      "start": "Aloita tilaus"
    }
  },
  "shopchanged": {
    "header": "Varoitus!",
    "text": "Sinulla on odottava tilaus toisessa ravintolassa kuin nyt valitussa ravintolassa."
  },
  "show-password": {
    "hide": "Piilota salasana",
    "show": "Näytä salasana"
  },
  "suggestedArticles": {
    "header": "Saisiko olla vielä jotain muuta?"
  },
  "termsAndConditions": {
    "closeButton": "Sulje",
    "header": "Käyttöehdot"
  },
  "updateApp": {
    "button": "Päivitä",
    "text": "Uusi versio on saatavilla, suorita päivitys jatkaaksesi. "
  }
}