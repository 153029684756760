export const messages = {
    sv: {
        home: {
            header: 'Välkommen till Sibylla \n - välj din restaurang!',
            subHeader: '(OBS! Onlinebeställningen stänger alltid en stund innan restaurangen)'
        },
        article:{
            allergens: '<span>Allergisk? Läs mer om innehåll och allergener </span><a href="https://www.sibylla.se/var-mat/allergener/">här.</a>'
        },
        label: {
            dineInPlace: 'Äta på restaurangen',
            takeAway: 'Ta med',
            articleChoiceLabel1: 'Extra burgare', 
            articleChoiceLabel2: 'Anpassa',
            articleChoiceLabel3: 'Välj tillbehör',
            articleChoiceLabel4: 'Ändra dryck',
            articleChoiceLabel5: 'Ändra tillval',
        },
        button: {
            pay: 'Till betalning',
            open: 'Öppet',
            closed: 'Stängt online',
            paused: 'Beställning pausad',
            offline: 'Stängt online'
        },
    },
    en:{},
    fi:{},
    no:{},
    ru:{}
}