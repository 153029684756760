import {ApplicationInsights} from "@microsoft/applicationinsights-web";

let appInsights:ApplicationInsights | null = null;

if(process.env.VUE_APP_INSIGHTS_CONN_STRING){
  appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.VUE_APP_INSIGHTS_CONN_STRING
    }
  });
  appInsights.loadAppInsights();
}

export function useApplicationInsights():ApplicationInsights | null {
  return appInsights;
}